import { useEffect, useState } from 'react'

import fetchAPI from '../../common/fetchAPI'
import ImageModalContent from './ImageModalContent'
import useModal from '../../common/hooks/useModal'
import Spinner from './Spinner'

function isFileImage(fileName) {
    return (
        fileName?.endsWith('.jpg') ||
        fileName?.endsWith('.jpeg') ||
        fileName?.endsWith('.png')
    )
}

function PrivateFile({
    driveFileId,
    openFullOnClick,
    fileData,
    usage,
    parentName,
}) {
    const [src, setSrc] = useState('')
    const [objectUrl, setObjectUrl] = useState('')
    const [fileName, setFileName] = useState(null)
    const [loading, setLoading] = useState(fileData ? false : true)
    const [error, setError] = useState('')
    const { setModal } = useModal()

    useEffect(() => {
        if (!driveFileId) return
        const getData = async () => {
            let response
            if (fileData) {
                response = fileData
            } else {
                setLoading(true)
                const imageUrl = usage
                    ? `/v1/files/private/usages/${usage}/${driveFileId}${
                          parentName ? `?parentName=${parentName}` : ''
                      }`
                    : `/v1/files/private/${driveFileId}`
                response = await fetchAPI(imageUrl, {}, 'GET')
                setLoading(false)
            }

            if (response.error) {
                return setError(response.error)
            }
            const { responseData, headers } = response

            const fileNameHeader = headers.get('Filename')
            setFileName(fileNameHeader)
            const blob = await responseData.blob()
            if (isFileImage(fileNameHeader)) {
                const reader = new FileReader()
                reader.onload = function () {
                    const src = this.result
                    setSrc(src)
                }
                reader.readAsDataURL(blob)
            } else {
                const url = window.URL.createObjectURL(blob)
                setObjectUrl(url)
            }
        }
        getData()
    }, [driveFileId])

    useEffect(() => {
        return () => {
            if (!objectUrl) return
            window.URL.revokeObjectURL(objectUrl)
        }
    }, [])

    const onClick = () => {
        if (openFullOnClick) {
            if (isFileImage(fileName)) {
                setModal(<ImageModalContent src={src} />)
            } else if (objectUrl) {
                const a = document.createElement('a')
                a.style = 'display: none'
                a.href = objectUrl
                a.download = fileName
                a.click()
            }
        }
    }

    return (
        <>
            {loading && <Spinner />}
            {error ? 'Error' : null}

            {!!src && isFileImage(fileName) && (
                <img
                    className="private-file is-image"
                    crossOrigin="Anonymous"
                    src={src}
                    alt=""
                    onClick={onClick}
                />
            )}

            {!isFileImage(fileName) && fileName && (
                <p className="private-file is-other" onClick={onClick}>
                    {fileName}
                </p>
            )}
        </>
    )
}

export default PrivateFile
