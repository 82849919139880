import { useState, useEffect } from 'react'
import { API_URL } from '../../common/constants'
import authStorage from '../../common/authStorage'
import useAuth from '../../common/hooks/useAuth'
import refreshAccessToken, {
    getTokenExpirationDate,
} from '../../common/refreshTokens'
import useToast from '../../common/hooks/useToast'

function Chat({ resource, resourceId }) {
    const [messages, setMessages] = useState([])
    const [ws, setWs] = useState(null)
    const auth = useAuth()
    const setToast = useToast()
    const [chatRoomId, setChatRoomId] = useState(null)

    useEffect(function () {
        const socket = new WebSocket(
            `${API_URL.replace('https', 'ws').replace('http', 'ws')}/?userId=${auth.user.id}`,
        )

        setWs(socket)

        socket.onopen = function () {
            const accessToken = authStorage.getItem('access_token')
            socket.send(
                JSON.stringify({
                    type: 'jwtConnect',
                    token: accessToken,
                    resource,
                    resourceId,
                }),
            )
        }

        socket.onmessage = async (event) => {
            const data = JSON.parse(event.data)

            if (data.status === 401) {
                await refreshAccessToken()
                setToast(data.message, 'alert')
                return
            }

            if (data.chatRoomId) {
                setChatRoomId(data.chatRoomId)
            }

            if (data.messages) {
                setMessages(data.messages)
            }

            if (!data.chatRoomId || data.messages) {
                return
            }

            setMessages((prevMessages) => [...prevMessages, data])
        }

        return function () {
            return socket.close()
        }
    }, [])

    async function sendMessage(content) {
        let hasExpiredToken = false

        const expirationDate = getTokenExpirationDate(
            authStorage.getItem('access_token'),
        )
        if (expirationDate < new Date()) {
            await refreshAccessToken()
            hasExpiredToken = true
        }

        ws.send(
            JSON.stringify({
                token: hasExpiredToken
                    ? authStorage.getItem('access_token')
                    : undefined,
                content,
                chatRoomId: chatRoomId,
                ...(!chatRoomId && { resource, resourceId }),
            }),
        )
    }

    return (
        <div>
            <ul>
                {messages.map((msg, index) => (
                    <li key={index}>
                        {msg.from}: {msg.content}
                    </li>
                ))}
            </ul>
            <button onClick={() => sendMessage('Hello Admin!', ws)}>
                Send
            </button>
        </div>
    )
}

export default Chat
