import { useState } from 'react'
import HtmlInput from '../../components/common/data-form/HtmlInput'
import Button from '../../components/common/Button'
import Input from '../../components/common/data-form/Input'
import InlineStack from '../../components/common/InlineStack'
import HorizontalScroll from '../../components/common/HorizontalScroll'
import ContactThumbnail from './clients/ContactThumbnail'
import ResourceList from '../../components/admin/ResourceList'
import HtmlEditor from '../../components/common/HtmlEditor'
import FilePicker from '../../components/common/FilePicker'
import useFilePicker from '../../components/common/FilePicker'
import Chat from '../../components/common/Chat'

export default function Dev() {
    const { pickerHtml, openPicker } = useFilePicker()
    return (
        <div>
            <Chat resource={'Task'} resourceId={'66b33658de329156edb8e9f9'} />
        </div>
    )
}
